.form-margin {
  margin-top: 30px;
}

.box-title {
  font-weight: 600;
  font-size: 2rem;
  text-align: center;
}

.login-btn {
  margin-top: 50px;
}

.box-extra {
  text-align: center;
  margin-top: 30px;
  font-size: 1.1rem;
}

.account-type-card {
  width: 100%;
  background: #F8F9FE;
  border: 1px solid #eee;
  margin-top: 35px;
  text-align: center;
  border-radius: 4px;
  padding: 30px 25px;
  margin-bottom: 25px;
}

.account-type-card:hover {
  background: #ff7f5022;
  border: 1px solid #ff7f5022;
  cursor: pointer;
  transition: 0.3s ease;
}

.account-type-card p {
  font-size: 1.2rem;
  margin-top: 25px;
  font-weight: bold;
  margin-bottom: 0px;
}

.account-type-card img {
  width: 100%;
  height: 100px;
}

.auth-code-info {
  text-align: center;
  font-size: 1rem;
  margin-top: -20px;
  margin-bottom: 25px;
  opacity: 0.7;
}

.auth-sub-info {
  text-align: center;
  font-size: 1rem;
  margin-top: 0px;
  margin-bottom: 25px;
  display: block;
}

.profile-loading {
  margin: 0 auto;
}

.profile-loader-container {
  text-align: center;
  font-size: 1.7rem;
  font-weight: bold;
}

.profile-loader-container img {
  width: 90%;
  max-width: 200px;
  margin-top: 30px;
  margin-bottom: 50px;
  animation: crescendo 1s alternate infinite ease-in;

}

@keyframes crescendo {
  0%   {transform: scale(.9);}
  100% {transform: scale(1.1);}
}